// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-cookies-cookietext-js": () => import("./../../../src/pages/cookies/Cookietext.js" /* webpackChunkName: "component---src-pages-cookies-cookietext-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-datenschutz-index-js": () => import("./../../../src/pages/datenschutz/index.js" /* webpackChunkName: "component---src-pages-datenschutz-index-js" */),
  "component---src-pages-impressum-index-js": () => import("./../../../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tag-index-js": () => import("./../../../src/pages/tag/index.js" /* webpackChunkName: "component---src-pages-tag-index-js" */),
  "component---src-templates-archive-archive-js": () => import("./../../../src/templates/archive/archive.js" /* webpackChunkName: "component---src-templates-archive-archive-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-tag-tag-js": () => import("./../../../src/templates/tag/tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */)
}

